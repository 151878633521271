.dateTimePickerField {
	width: 100%;
	.MuiFormControl-root{
		width: 100%;
	}
	.MuiOutlinedInput-input.MuiInputBase-input {
		padding-top: 6px;
		padding-bottom: 14px;
	}
	.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedEnd {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.MuiInputLabel-root.MuiInputLabel-formControl {
		line-height: 1em;
	}
	.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
		padding-top: 12px;
	}
}