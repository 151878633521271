.cardWidget {
	padding: 1.5rem;
}

.cardHeadingWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.5rem;
}