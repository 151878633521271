.sidebarWrapper {
	border-radius: 0 !important;
	height: 100vh;
	position: fixed;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	left: 0;
	top:0;
	min-width: 16.666667%
}

.sidebarLogoWrapper {
	display: flex;
	padding: 1rem;
	justify-content: center;
}

