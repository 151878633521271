.ProseMirror
{
	position: relative;
	> * + *
	{
		margin-top: 0.75em;
	}

	ul,
	ol
	{
		padding: 0 1rem;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6
	{
		line-height: 1.1;
	}

	code
	{
		background-color: rgba(#616161, 0.1);
		color:            #616161;
	}

	img
	{
		max-width: 100%;
		height:    auto;
	}

	hr
	{
		margin: 1rem 0;
	}

	blockquote
	{
		padding-left: 1rem;
		border-left:  2px solid rgba(#0D0D0D, 0.1);
	}

	pre
	{
		background:    #0d0d0d;
		color:         #fff;
		font-family:   "JetBrainsMono", monospace;
		padding:       0.75rem 1rem;
		border-radius: 0.5rem;

		code
		{
			color:      inherit;
			padding:    0;
			background: none;
			font-size:  0.8rem;
		}

		.hljs-comment,
		.hljs-quote
		{
			color: #616161;
		}

		.hljs-variable,
		.hljs-template-variable,
		.hljs-attribute,
		.hljs-tag,
		.hljs-name,
		.hljs-regexp,
		.hljs-link,
		.hljs-name,
		.hljs-selector-id,
		.hljs-selector-class
		{
			color: #f98181;
		}

		.hljs-number,
		.hljs-meta,
		.hljs-built_in,
		.hljs-builtin-name,
		.hljs-literal,
		.hljs-type,
		.hljs-params
		{
			color: #fbbc88;
		}

		.hljs-string,
		.hljs-symbol,
		.hljs-bullet
		{
			color: #b9f18d;
		}

		.hljs-title,
		.hljs-section
		{
			color: #faf594;
		}

		.hljs-keyword,
		.hljs-selector-tag
		{
			color: #70cff8;
		}

		.hljs-emphasis
		{
			font-style: italic;
		}

		.hljs-strong
		{
			font-weight: 700;
		}
	}
}

.bubble-menu
{
	display:          flex;
	background-color: #0d0d0d;
	padding:          0.75rem;
	border-radius:    0.25rem;
	border: 1px solid #999999;

	button
	{
		border:      none;
		background:  none;
		color:       #fff;
		font-size:   1rem;
		font-weight: 500;
		padding:     0 0.2rem;
		opacity:     0.6;

		&:hover,
		&.is-active
		{
			opacity: 1;
		}
	}
}

.floating-menu
{
	display:          flex;
	background-color: #ffffff;
	padding:          0.75rem;
	border-radius:    0.25rem;
	border: 1px solid #999999;
	margin-top: 6rem;

	button
	{
		border:      none;
		background:  none;
		font-size:   1rem;
		font-weight: 500;
		padding:     0 0.2rem;
		opacity:     0.6;

		&:hover,
		&.is-active
		{
			opacity: 1;
		}
	}
}

/* Table-specific styling */
.ProseMirror
{
	table
	{
		border-collapse: collapse;
		table-layout:    fixed;
		width:           100%;
		margin:          0;
		overflow:        hidden;

		td,
		th
		{
			min-width:      1em;
			border:         2px solid #ced4da;
			padding:        3px 5px;
			vertical-align: top;
			box-sizing:     border-box;
			position:       relative;

			> *
			{
				margin-bottom: 0;
			}
		}

		th
		{
			font-weight:      bold;
			text-align:       left;
			background-color: #f1f3f5;
		}

		.selectedCell:after
		{
			z-index:        2;
			position:       absolute;
			content:        "";
			left:           0;
			right:          0;
			top:            0;
			bottom:         0;
			background:     rgba(200, 200, 255, 0.4);
			pointer-events: none;
		}

		.column-resize-handle
		{
			position:         absolute;
			right:            -2px;
			top:              0;
			bottom:           -2px;
			width:            4px;
			background-color: #adf;
			pointer-events:   none;
		}
	}
}

.tableWrapper
{
	overflow-x: auto;
}

.resize-cursor
{
	cursor: ew-resize;
	cursor: col-resize;
}


.ProseMirror .is-empty::before {
	content: attr(data-placeholder);
	float: left;
	color: #ced4da;
	pointer-events: none;
	height: 0;
}

.ProseMirror
{
	border:           none !important;
	min-height:       600px;

	&:focus-visible, &:focus
	{
		border: none !important;
		outline: none !important;
	}

	&:focus-visible
	{
		border: none !important;
		outline: none !important;
	}
}

.tipTapEditorWrapper .MuiBox-root{
	&:focus-visible
	{
		border: none !important;
		outline: none !important;
	}
}