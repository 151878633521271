.sidebarAccordion {
	box-shadow: none !important;
	margin-bottom: .75rem;
}


.sidebarAccordion .MuiAccordionSummary-content.Mui-expanded {
	margin: 0;
}

.sidebarAccordion .MuiAccordionSummary-root.Mui-expanded {
	min-height: 42px;
}

.sidebarAccordion.MuiAccordion-root::before {
	display: none;
}


.sidebarAccordion .MuiAccordionDetails-root {
	padding-top: 0;
	padding-bottom: 0;
}

.accordionIcon {
	margin-right: .5rem;
}

.sidebarButton {
	text-decoration: none;
	text-transform: capitalize !important;
	justify-content: flex-start !important;
	flex: 1 1 auto;
}

.sidebarButton.active {
	background-color: var(--light-primaryBg);
}