:root {
	--borderRadius: 4px;
	--borderRadiusLarge: 8px;
	--white: #ffffff;
	--black: #222222;
	--light-border:'rgba(0,0,0,0.12)';
	--light-primaryMain: #7E22CE;
	--light-primaryLight: #a855f7;
	--light-primaryDark: #581c87;
	--light-primaryBg: rgba(126, 34, 206, .08);
	--light-greyBgLight: rgba(0, 0, 0, .08);
}